<template>
  <div>
    <h5>Heads Up!</h5>
    <p>Our mail courier requires us to submit a form to process mail forwarding for our clients. Please download USPS
      Form 1583, fill it out, and upload it here so we can move forward with providing you the best service.</p>
    <a :href="$store.state.user.company.mail_delivery_form_path" download target="_blank">Click here to download Form
      1583</a>
    <hr>
    <div v-if="$store.state.user.company.mail_delivery_status == 3">
      <div>Your recent uploaded file has been rejected with remarks:
        <p class="orange-text">"{{ $store.state.user.company.mail_delivery_remarks
          }}"</p>
      </div>
    </div>
    <el-upload class="upload-demo" ref="upload" :multiple="false" :auto-upload="false" action=""
      :before-upload="checkFile" :on-change="checkFile" :file-list="fileList">
      <el-button slot="trigger" size="small" type="primary" plain>Select file</el-button>
      <el-button style="margin-left: 10px;" size="small" type="primary" @click="submitUpload"
        v-loading="loading" :disabled="!form.file">Upload</el-button>
        <el-button v-if="!hide_skip" plain style="float: right;" size="small" @click="skip"
        v-loading="loading" >Skip</el-button>
    </el-upload>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

import { Company } from '@/resources/company'

extend("required", required);
extend("email", email);
import Swal from 'sweetalert2'

export default {
  props: ['user_location','hide_skip'],
  data() {
    return {
      form: {
        file: null,
        file_name: null
      },
      loading: false,
      fileList: []
    };
  },
  watch: {
    form: {
      handler: function (newVal) {
      },
      deep: true
    }
  },
  methods: {
    checkFile(file, fileList) {
      if (file) {
        this.fileList = [file]
        this.form.file = file.raw
        this.form.file_name = file.name
      }
    },
    async skip() {
       
      const { value: res } = await Swal.fire({
        title: "Note",
        text: "You can upload the delivery form at any time. Simply click on the delivery form from the dashboard.Click Ok to proceed or click anywhere to go back",
        icon: "info",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      if (!res) return false
      this.loading = true
       Company.skipDeliveryForm()
        .then(result => {
           this.$emit('close', true)
          this.$router.push({ name: 'Home', query: { refresh: true } })
        })
        .catch(error => {
          this.$notify({
            message: 'Unable to save at the moment',
            type: 'danger'
          })
        }).finally(() => {
          this.loading = false
        })
    },
    submitUpload() {
      // this.$refs.upload.submit();
      this.loading = true
      var formData = new FormData()
      formData.append('file', this.form.file)
      formData.append('file_name', this.form.file_name)
      Company.uploadForm(formData)
        .then(result => {
          this.success()
        })
        .catch(error => {
          this.$notify({
            message: 'Unable to upload this file at the moment',
            type: 'danger'
          })
        }).finally(() => {
          this.loading = false
        })
    },
    async success() {
      const { value: res } = await Swal.fire({
        title: "Success",
        text: "Thank you for submitting the form. Our system will review the file and notify you if any new information arises.",
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.$emit('close', true)

      this.$router.push({ name: 'Home', query: { refresh: true } })
    },
  }
}
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
