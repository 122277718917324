export default [
  {
    name: 'Home',
    icon: 'nc-icon nc-bank',
    path: '/overview'
  },
  {
    name: 'MailBox',
    icon: 'fa fa-envelope',
    collapsed: true,
    module: 'mailbox',
    action: 'view',
    children: [
      {
        name: 'Inbox',
        path: '/mailbox/inbox',
        module: 'mailbox',
        action: 'read'
      }
    ]
  },
  // {
  //   name: 'Partners Page',
  //   icon: 'fa fa-envelope',
  //    path: '/partenrs-checkpoint',
  //   module: 'PARTNERS_DASHBOARD',
  //   action: 'view',
  // },
  {
    name: 'My Commissions',
    icon: 'fa fa-envelope',
     path: '/my-commissions',
    module: 'my_commissions',
    action: 'view',
  },
  {
    name: 'Referred Clients',
    icon: 'fa fa-envelope',
    path: '/referred-clients',
    module: 'my_commissions',
    action: 'view',
  },
  {
    name: 'Transactions',
    icon: 'fa fa-money',
    collapsed: true,
    module: 'transactions',
    action: 'view',
    children: [
      {
        name: 'List',
        path: '/transactions/list',
        module: 'transactions',
        action: 'read'
      },
    ]
  },
  {
    name: 'All Mails',
    icon: 'fa fa-envelope-o',
    collapsed: true,
    module: 'mails',
    children: [
      {
        name: 'List',
        path: '/mails/list',
        module: 'mails',
        action: 'read'
      },
      {
        name: 'New',
        path: '/mails/new',
        module: 'mails',
        action: 'create'
      },
      {
        name: 'Open Requests',
        path: '/mails/open',
        module: 'mails',
        action: 'create'
      },
      {
        name: 'Forward Requests',
        path: '/mails/forward',
        module: 'mails',
        action: 'create'
      },
      // {
      //   name: 'Trash Requests',
      //   path: '/mails/trash',
      //   module: 'mails',
      //   action: 'create'
      // },
      {
        name: 'Shred Requests',
        path: '/mails/shred',
        module: 'mails',
        action: 'create'
      }
    ]
  },

  {
    name: 'Subscription',
    icon: 'fa fa-bookmark',
    path: '/subscription/view',
    module: 'mailbox',
    action: 'view'
  },

  {
    name: 'User Referrals',
    icon: 'fa fa-bookmark',
    path: '/user-referrals',
    module: 'user_referrals',
    action: 'view'
  },

  {
    name: 'Clients',
    icon: 'fa fa-group',
    path: '/clients',
    module: 'clients',
    action: 'view'
  },

  {
    name: 'Meeting Room',
    icon: 'fa fa-building',
    path: '/meeting-room',
    module: 'transactions',
    action: 'view',
    children: [
      {
        name: 'Search Rooms',
        path: '/meeting-room/search',
        module: 'transactions',
        action: 'view'
      },
      {
        name: 'Reservations',
        path: '/meeting-room/bookings',
        module: 'transactions',
        action: 'view'
      },
      {
        name: 'All Reservations',
        path: '/meeting-room/manage-bookings',
        module: 'MEETING_ROOMS',
        action: 'view'
      },
      {
        name: 'Manage Rooms',
        path: '/meeting-room/manage',
        module: 'MEETING_ROOMS',
        action: 'view'
      },
    ]
  },

  {
    name: 'Co Working Space',
    icon: 'fa fa-address-card',
    path: '/co-working-space',
    module: 'transactions',
    action: 'view',
    children: [
      {
        name: 'Search working space',
        path: '/co-working-space/search',
        module: 'transactions',
        action: 'view'
      },
      {
        name: 'Reservations',
        path: '/co-working-space/bookings',
        module: 'transactions',
        action: 'view'
      },
      {
        name: 'All Reservations',
        path: '/co-working-space/manage-bookings',
        module: 'MEETING_ROOMS',
        action: 'view'
      },
      {
        name: 'Manage Co-working Space',
        path: '/co-working-space/manage',
        module: 'MEETING_ROOMS',
        action: 'view'
      },
      
    ]
  },
  {
    name: 'Settings',
    icon: 'fa fa-cogs',
    collapsed: true,
    module: 'settings',
    children: [
      {
        name: 'Users',
        path: '/settings/users',
        module: 'users',
        action: 'view'
      },
      {
        name: 'Roles',
        path: '/settings/roles',
        module: 'roles',
        action: 'view'
      },
      {
        name: 'Plans',
        path: '/settings/plans',
        module: 'plans',
        action: 'view'
      },
      {
        name: 'Offices',
        path: '/settings/offices',
        module: 'offices',
        action: 'view'
      },
      {
        name: 'Promos',
        path: '/settings/promos',
        module: 'promos',
        action: 'view'
      },
      {
        name: 'Holidays',
        path: '/settings/holidays',
        module: 'holidays',
        action: 'view'
      }
    ]
  },
  {
    name: 'Logout',
    icon: 'fa fa-sign-out',
    path: '/logout'
  }
]
