<template>
<div class="login-page">
  <app-navbar></app-navbar>
  <div class="wrapper wrapper-full-page">
    <div class="full-page login-page section-image">
      <div class="content">
        <div class="container">
          <div class="col-lg-4 col-md-6 ml-auto mr-auto">
            <form @submit.prevent="login">
              <card type="login">
                <h3 slot="header" class="header text-center">Login</h3>
                <el-alert v-if="message" :title="message" type="error">
                </el-alert>
                <el-input placeholder="Username or Email" v-model="form.email">
                  <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
                </el-input>

                <el-input type="password" placeholder="Password" v-model="form.password">
                  <i slot="prefix" class="el-input__icon el-icon-unlock"></i>
                </el-input>

                <br>
                <template slot="footer">
                  <p-button native-type="submit" type="warning" round block class="mb-3" v-loading="loading" :disabled="loading">
                    {{ loading ? 'Signing in' : 'Sign in' }}
                  </p-button>
                  <div style="text-align: center;
    margin-bottom: 8px;
    color: #918888;
    font-size: 12px;">OR</div>
                  <p-button native-type="button" type="info" round block class="mb-3" :disabled="loading" @click="goToRegister()">
                    Create a new account now
                  </p-button>

                   <p-button native-type="button" type="danger" round block class="mb-3" :disabled="loading" @click="goToRegister('partner')">
                    Sign up as a partner
                  </p-button>

                  <el-link type="warning" class="nav-link danger-link" href="/forgot-password">Forgot Password?</el-link>
                </template>
              </card>

            </form>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
      <background></background>
    </div>
  </div>
</div>
</template>

<script>
import {
  Card,
  Checkbox,
  Button
} from 'src/components/UIComponents';
import AppNavbar from './Layout/AppNavbar'
import AppFooter from './Layout/AppFooter'
import {
  Authenticate
} from '@/resources/authenticate'
import {
  mapState
} from 'vuex';

export default {
  computed: mapState(['token']),
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button
  },
  created: function () {
    if (this.token) this.getUser()
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    goToRegister(data) {
      let query = {}
      if (data == 'partner') query.partner = 'yes'
      this.$router.push({
        name: 'Register',
        query: query
      })
    },
    getUser() {
      let self = this
      this.loading = true
      Authenticate.getUser(function (result) {
        if (result.user) {
          self.$router.push({
            name: 'Home'
          })
        }
        self.loading = false
      })
    },
    login() {
      var self = this
      this.loading = true
      Authenticate.login(this.form, function (user, message) {
        if (user) {
          let redirect = self.$route.query.redirect
          if (redirect) {
            self.$router.push(redirect)
          } else {
            self.$router.push({
              name: 'Home'
            })
          }
        } else {
          self.showAlert(message)
        }
        self.loading = false
      })
    },
    showAlert(message) {
      this.message = message
      let vm = this
      setTimeout(function () {
        vm.message = null
      }, 5000)
    },
  },

  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      loading: false,
      message: null
    }
  },
  beforeDestroy() {
    this.closeMenu()
  }
}
</script>

<style>
</style>
