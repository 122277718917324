import api from "./axios";
import { LocalStorageService } from '@/services/LocalStorageService'
import store from '../store/index'

export default {
  getHeader() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+LocalStorageService.getToken(),
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Origin": "*"
    }
  },
  getFormDataHeader() {
    return {
      'Authorization' : 'Bearer '+LocalStorageService.getToken()
    }
    // return store.state.formDataHeaders
  },
  createFormData(url, params) {
    console.log(this.getFormDataHeader())
    return api.post(url, params, {headers: this.getFormDataHeader()})
  },
  updateFormData(url, params) {
    return api.post(url, params, {headers: this.getFormDataHeader()})
  },
  create(url, params) {
    return api.post(url, params, {headers: this.getHeader()})
  },
  get(url, data) {
    if (!data) data = {}
    return api.get(url, {params: data.params, headers: this.getHeader()})
  },
  update(url, params) {
    return api.put(url, params, {headers: this.getHeader()})
  },
  delete(url, params) {
    return api.delete(url, {params: params, headers: this.getHeader()})
  }
}
