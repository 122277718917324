<template>
  <nav :class="classes" class="navbar navbar-expand-lg">

    <div class="container-fluid">
      <slot></slot>
      <slot name="toggle-button">
        <button aria-controls="navigation-index" @click="toggleMenu" aria-expanded="true" aria-label="Toggle navigation"
          class="navbar-toggler" data-toggle="collapse" type="button"><span
            class="navbar-toggler-bar navbar-kebab"></span><span class="navbar-toggler-bar navbar-kebab"></span><span
            class="navbar-toggler-bar navbar-kebab"></span></button>
      </slot>
      <CollapseTransition>
        <div class="collapse navbar-collapse justify-content-end show" :class="navbarMenuClasses" v-show="showNavbar"
          id="navigation">
          <ul class="navbar-nav">
            <slot name="navbar-menu"></slot>
          </ul>
        </div>
      </CollapseTransition>

      <div v-if="user && user.company" class="bill-alert">
        <el-alert v-if="shouldShowExpireAlert()" type="error" effect="dark" show-icon>
          <slot name="title" v-if="!user.company.company_plan.is_cancel">
            <div>{{ 'Your Mailbox subscription has already expired.' }}
              <el-link type="info" href="/subscription/renew">Click here</el-link> if you wish to renew the service
            </div>
          </slot>
          <slot name="title" v-if="user.company.company_plan.is_cancel">
            <div>You have cancelled your Mailbox subscription.
              <el-link type="info" href="/subscription/renew">Click here</el-link> if you wish to reactivate the service
            </div>
          </slot>
        </el-alert>
      </div>
    </div>

    <el-dialog title="Mail Delivery Form" :visible.sync="showForm" width="40%" :append-to-body="true"
      :show-close="false" :close-on-press-escape='false' :close-on-click-modal="false">
      <mail-delivery-form @close="showForm = false"></mail-delivery-form>
    </el-dialog>
  </nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
import { User } from '@/resources/user'
import MailDeliveryForm from './MailDeliveryForm.vue';

  export default {
    name: 'navbar',
    props: {
      showNavbar: {
        type: Boolean,
        description: "Whether navbar is visible"
      },
      navbarMenuClasses: {
        type: [String, Object],
        description: 'Navbar menu css classes'
      },
      transparent: {
        type: Boolean,
        default: true,
        description: 'Whether navbar is transparent'
      },
      position: {
        type: String,
        default: 'absolute',
        description: 'Navbar position (absolute|fixed|relative)'
      },
      type: {
        type: String,
        default: 'white',
        validator(value) {
          return ['white', 'default', 'primary', 'danger', 'success', 'warning', 'info'].includes(value);
        },
        description: 'Navbar type (primary|info|danger|default|warning|success)'
      }
    },
    model: {
      prop: 'showNavbar',
      event: 'change'
    },
    components: {
      CollapseTransition,
      MailDeliveryForm
    },
    computed: {
      classes() {
        let color = `bg-${this.type}`;
        let navPosition = `navbar-${this.position}`;
        return [
          { 'navbar-transparent': !this.showNavbar && this.transparent },
          { [color]: this.showNavbar || !this.transparent },
          navPosition]
      }
  },
  data() {
    return {
      user: null,
      showCompanyProfile: false,
      company: null,
      showForm: false
    };
  },
    watch:{
      $route (to, from){
        this.checkCredential()
      },
      user() {
        if (this.user) {
          if (!this.user.company) {
            if (this.user.role_names && this.user.role_names.includes('client')) {
              if (this.$route.name !== 'CompanyPlan') {
                this.$router.push({ name: 'CompanyPlan' })
              }
            }
          }
        }
      }
    },
  created: function () {
      this.checkCredential()
    },
  methods: {
      shouldShowExpireAlert() {
        return  this.$route.name != 'RenewSubscription' && this.user.company.minutes_before_due_date <= 0
      },
      checkCredential() {
        let publicRoutes = ['Login', 'Register', 'ResetPassword', 'ForgotPassword', "Logout"]
     
        if (!publicRoutes.includes(this.$route.name)) {
          if (!this.$store.state.user) {
            alert("You have to login again")
            this.$router.push({ name: 'Login' , query: {redirect: this.$route.path }})
          } else {
            this.user = this.$store.state.user

            if (this.user.company) {

              if (this.$route.name == 'Overview') {
                this.refreshCompany()
              } else {
                this.checkPaidPlan()

              }
            } else {
              if (this.$route.name != 'CompanyPlan' && this.user.role_names && this.user.role_names.includes('client')) {
                this.$router.push({ name: 'CompanyPlan' })
                return false
              }

              if (this.user.role_names.includes('partner')) {
                if (!this.user.partner_profile) {
                  if (this.$route.name != 'PartnersPage') this.$router.push({ name: 'PartnersPage' })
                } else {
                  if (this.$route.name == 'PartnersPage') this.$router.push({ name: 'Home' })
                }
              }
            }
          }
        }
    },
    checkCompanyData() {
      if (!this.user.company.company_profile) {
        if (this.$route.name !== 'CompanyProfile') this.$router.push({ name: 'CompanyProfile' })
        return false
      }

      if (this.user.company.company_plan && !this.user.user_location_id) {
        if (this.$route.name !== 'UserLocation') this.$router.push({ name: 'UserLocation' })
        return false
      }

      if (this.user.company && this.$route.name == 'CompanyPlan') {
        this.$router.push({ name: 'Home' })
        return false
      }

      this.showForm = this.user.company.delivery_form_required
    },
      checkPaidPlan() {
        if (this.user && this.user.company && this.user.company.company_plan && !this.user.company.has_paid_the_plan) {
          if (this.$route.name != 'PayPlan') this.$router.push({ name: 'PayPlan', query: { pay_plan: true } })
          return
        }
        this.checkCompanyData()
      },
      refreshCompany() {
        User.settings()
          .then(result => {
            this.user = result.data.user
            this.user.company = result.data.company
            this.user.permissions = result.data.permissions
            
            this.$store.commit('saveUser', this.user);
            this.checkPaidPlan()
          })
          .catch(error => {
            
          }).finally(() => {
            this.loading = false
          })
      },
      toggleMenu() {
        this.$emit('change', !this.showNavbar);
      }
    }
  }
</script>
<style scoped>
  .navbar-relative {
    position: relative;
  }

</style>
